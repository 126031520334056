import React from "react";
import '../index.less'

export interface ZTimelineItemProps {
    children?: React.ReactNode
    color?: string
    line?: boolean
}

const ZTimelineItem = (props: ZTimelineItemProps) => {
    const {children, color = '#dddddd', line = true} = props
    return (
        <div className="line-item">
            <div className="line-item-dot" style={{border: `2px solid ${color}`}}/>
            {line && <div className="line-item-line"/> || ''}
            <div className="line-item-content" style={{color}}>{children}</div>
        </div>
    )
}
export default ZTimelineItem