import React from "react";
import './index.less'
import ZTimelineItem from "@/components/Timeline/item";

export interface TimelineProps<T = any> {
    children?: React.ReactNode
    options?: T
}

const ZTimeline = (props: TimelineProps) => {

    const {children} = props
    return <div className="timeline">{children}</div>
}

ZTimeline.Item = ZTimelineItem
export default ZTimeline